import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Grid, LinearProgress } from "@material-ui/core";
import { ChevronRightSharp } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";

import { getUser } from 'services/auth';

import { Title, Text, Line, CustomLink } from "pages/components/styles";
import Header from "pages/components/Header";
import Footer from "pages/components/Footer";
import Button from "pages/components/Button";
import { Card } from "pages/components/styles";
import SigninSymbol from "assets/icons/signin-symbol.png";

import { ErrorContent, MainContent } from "./styles";

// Actions
import { Creators } from 'store/ducks/signin';
import { Creators as CreatorsValidate } from 'store/ducks/validate';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const Index = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { pending, error, user } = useSelector(state => state.signin);
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    proccessLogin();
  });

  useEffect(() => {
    if(!error && user && user?.hash){
      proccessLogin();
    }
  },[user]);
  
  const proccessLogin = () => {
    const userLogged = getUser();
    if(userLogged && userLogged?.hash) {
      navigate('/conta');
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(Creators.fetchSignin(String(email).trim(), String(password).trim()));
  };
  
  const handleTryAgain = () => dispatch(Creators.signinReset());

  return (
    <div>
      <Header />

      <MainContent bgColor={'#FBFBFB'}>
        <Grid container spacing={3} style={{width: 'calc(100% - 24px)'}}>
          <Grid item xs={12} md={6}>
            <Card toFit>
              
              {pending && <LinearProgress />}
              
              <form onSubmit={handleSubmit}>
                { error && 
                  <ErrorContent>
                  <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <Title bigger center>Acesso Negado!</Title>
                        <Title center>Verifique o E-mail e/ou a Senha informada</Title>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Button fullWidth bgColor="#3FB992"
                          text={'Tentar Novamente'}
                          onClick={handleTryAgain}
                        />
                      </Grid>
                    </Grid>
                  </ErrorContent>
                }
                { !error && <>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Title bigger center>Entre com a sua conta</Title>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <TextField disabled={pending} variant="outlined" fullWidth
                        value={email} required
                        type="email"
                        onChange={e => setEmail(e.target.value)} 
                        placeholder="Endereço de e-mail"
                        className={classes.textField}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <TextField disabled={pending} variant="outlined" fullWidth
                        value={password} required
                        type="password" autoComplete="off"
                        onChange={e => setPassword(e.target.value)} 
                        placeholder="Senha"
                        className={classes.textField}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Button pending={pending}
                        fullWidth
                        type="submit"
                        bgColor="#3FB992"
                        text={'E N T R A R'}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} style={{textAlign:'center', alignSelf:'center', paddingTop:'40px', paddingBottom:'40px'}}>
                      <CustomLink href="/recover">Esqueceu sua senha?</CustomLink>
                    </Grid>
                  </Grid>
                </>
                }
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} style={{textAlign:'center', alignSelf:'center', paddingTop:'40px', paddingBottom:'40px'}}>
                    <div><Text>Ainda não possui conta?</Text></div>
                    <div><CustomLink href="/cadastro">Cadastre-se aqui</CustomLink></div>
                  </Grid>
                </Grid>
              </form>
              {pending && <LinearProgress />}
            </Card>
          </Grid>

          <Grid item xs={12} md={6} style={{textAlign:'center', alignSelf:'center'}}>
            <img src={SigninSymbol} style={{width: '100%', maxWidth:'310px', maxHeight:'290px'}} />
          </Grid>
        </Grid>

        <div style={{width:'100%', height:'50px'}} />
      </MainContent>

      <Footer />
    </div>
  );
}

export default Index
