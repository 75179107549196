import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

// Actions
import { Creators } from 'store/ducks/city';

function Index(props) {
  const dispatch = useDispatch();
  const { pending, data, error } = useSelector(state => state.city);

  const handleTextChange = (e) => {
    const { value } = e.target;
    if(String(value).trim().length >= 4) {
      dispatch(Creators.fetchCitiesByName(value));
    }
  }

  return (
    <>
      <Autocomplete style={{backgroundColor:'#FFFFFF', borderRadius:'5px', marginTop:'16px', marginBottom:'8px'}}
        // multiple
        id="auto-complete-cities"
        options={data}
        loading={pending}
        getOptionLabel={(option) => option.name ? props.showState ? `${option.name}-${option.uf}` : `${option.name}` : ''}
        value={props.values || []}
        onChange={props.onChange}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} label={props.label} variant="outlined" placeholder={props.placeholder ? props.placeholder : "Cidade"} onChange={handleTextChange} />
        )}
      />
    </>
  );
}

export default Index;