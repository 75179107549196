import "date-fns";
import MomentUtils from "@date-io/moment";
import React, { useState } from "react";
import {
  makeStyles,
  TextField,
  Grid,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Select,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import moment from "moment-timezone";

import { Text, Line } from "pages/components/styles";
import Button from "pages/components/Button";

import HIRING_TYPES from "assets/data/hiring-types.json";
import COMPANY_BUSINESS from "assets/data/company-business.json";

const useStyles = makeStyles((theme) => ({
  textField: {
    borderColor: "#FFFFFF",
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    marginRight: "5px",
  },
  selectField:{
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    marginTop: "16px",
    marginBottom: "8px",
  }
}));

function Index(props) {
  const classes = useStyles();
  const { model, setValue, onNext, onBack } = props;

  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setValue({
      ...model,
      job_expiration: moment(date).format("YYYY/MM/DD"),
    });
    setSelectedDate(date);
  };

  return (
    <div>
      <Line>
        <Text bold>Informações gerais</Text>
      </Line>

      <div>
        <form onSubmit={onNext}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField required fullWidth variant="outlined" margin="normal"
                label="Título da vaga"
                placeholder="Psicologo"
                value={model.job_title}
                onChange={(e) =>
                  setValue({ ...model, job_title: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                className={classes.textField}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField required fullWidth variant="outlined" margin="normal"
                label="Nome da empresa (não será exibido)"
                placeholder=""
                value={model.company}
                onChange={(e) =>
                  setValue({ ...model, company: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                className={classes.textField}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl required fullWidth variant="outlined" className={classes.selectField}>
                <InputLabel id="company-business-label">Área de atuação</InputLabel>
                <Select labelId="company-business-label"
                  value={model.company_business}
                  onChange={(e) =>setValue({...model, company_business: e.target.value})}
                >
                  {[...COMPANY_BUSINESS].map((e, idx) => (
                    <MenuItem key={`company-business-item-${idx}`} value={e.id}>
                      {e.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField fullWidth variant="outlined" margin="normal"
                label="Salário (R$ 1.200,00 / A Combinar)"
                placeholder="A combinar"
                value={model.job_salary}
                onChange={(e) =>
                  setValue({ ...model, job_salary: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
                className={classes.textField}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container justifyContent="space-around">
                  <KeyboardDatePicker required fullWidth variant="outlined" margin="normal"
                    disableToolbar
                    format="DD/MM/YYYY"
                    label="Data de expiração da vaga"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "Alterar Data",
                    }}
                    className={classes.selectField}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl required fullWidth variant="outlined" className={classes.selectField}>
                <InputLabel id="hiring-type-label">Tipo de contratação</InputLabel>
                <Select fullWidth variant="outlined"
                  labelId="hiring-type-label"
                  value={model.job_tphiring}
                  onChange={(e) =>
                    setValue({
                      ...model,
                      job_tphiring: e.target.value,
                    })
                  }
                >
                  {[...HIRING_TYPES].map((e, idx) => (
                    <MenuItem key={`hiring-type-item-${idx}`} value={e.id}>
                      {e.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl required component="fieldset">
                <FormLabel component="legend">
                  Modalidade de trabalho*
                </FormLabel>
                <RadioGroup
                  aria-label="Modalidade de trabalho*"
                  style={{ flexDirection: "row" }}
                  value={model.job_modality}
                  onChange={(e) =>
                    setValue({
                      ...model,
                      job_modality: e.target.value,
                    })
                  }
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="Presencial"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio color="primary" />}
                    label="Home Office"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio color="primary" />}
                    label="Híbrido"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField required fullWidth variant="outlined" margin="normal" 
                multiline minRows={4}
                label="Descrição da vaga*"
                value={model.job_description}
                onChange={(e) =>
                  setValue({
                    ...model,
                    job_description: e.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
                className={classes.textField}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField required fullWidth variant="outlined" margin="normal"
                multiline minRows={4}
                label="Requisitos*"
                value={model.job_requirements}
                onChange={(e) =>
                  setValue({
                    ...model,
                    job_requirements: e.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
                className={classes.textField}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                type="submit"
                width={"150px"}
                bgColor="#3FB992"
                text={"Continuar"}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default Index;
