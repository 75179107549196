import { call, put } from 'redux-saga/effects';
import api from 'services/api';
import { Creators } from 'store/ducks/city';

export function* getCitiesByName({ name }) {
  try {
    const response = yield call(api.get, `/v1/public/cities?name=${name}`);
    const { body } = response.data;
  
    yield put(Creators.fetchCitiesByNameSuccess(body));
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.fetchCitiesByNameFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}
