import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from "@material-ui/core";
import { ChevronRightSharp, EmailRounded } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import Header from "pages/components/Header";
import Footer from "pages/components/Footer";
import JobModel from "models/job";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import { Title, Text, Line, CustomLink } from "pages/components/styles";
import { MainContent, GrayCenterArea} from "../../../jobs/styles";

import { getUser } from 'services/auth';

// Actions
import { Creators } from 'store/ducks/jobs';

function Index(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, pending, error } = useSelector(state => state.jobs);
  
  const [userLogged, setUserLogged] = useState(null);

  const [model, setModel] = useState(JobModel);
  const [step, setStep] = useState(1);

  const backStep = () => setStep(step > 1 ? step-1 : 1);
  const nextStep = () => setStep(step < 3 ? step+1 : 3);
  const setValue = value => setModel(value);

  useEffect(() => {
    if(data && data.id) {
      navigate('/conta/vagas');
    }
  },[data]);

  useEffect(() => {
    const _user = getUser();
    setUserLogged(_user);
    if(!_user || !_user.hash) {
      navigate('/login');
    }
    setValue({...model, user_id:String(_user?.id), status:"1"});
  },[]);

  const onSubmit = () => {
    console.log('onSubmit', model);
    dispatch(Creators.postJob(model));
  }

  return <div>
    <Header />

    {
      userLogged &&
      <MainContent bgColor={'#FFFFFF'}>
        <Line>
          <Text flex> <CustomLink href="/">Home</CustomLink> <ChevronRightSharp /> <CustomLink href="/conta">Conta</CustomLink> <ChevronRightSharp /> Nova Vaga </Text>
        </Line>

        <div style={{textAlign:'center'}}>
          <Title bigger center color={'#3FB992'}>Publicar uma vaga</Title>
          <Text medium>Adicione as informações basicas da vaga.</Text>
        </div>

        <div>
          <GrayCenterArea light>
            {step === 1 && <Step1 model={model} setValue={setValue} onNext={nextStep} onBack={backStep} />}
            {step === 2 && <Step2 model={model} setValue={setValue} onNext={nextStep} onBack={backStep} />}
            {step === 3 && 
              <>
                {pending && <LinearProgress />}
                <Step3 model={model} pending={pending} setValue={setValue} onSubmit={onSubmit} onBack={backStep} />
                {pending && <LinearProgress />}
              </>
            }
          </GrayCenterArea>

        </div>
      </MainContent>
    }

    <Footer />
  </div>
}

export default Index;
