import moment from "moment-timezone";
const JobModel = {
  id: "",
  user_id: "",
  city_id: "",
  city_code: "",
  city_name: "",
  city_uf: "",
  address_neighborhood: "",
  address_street: "",
  address_postal_code: "",
  address_number: "",
  address_complement: "",
  company: "",
  company_business: "",
  job_title: "",
  job_requirements: "",
  job_description: "",
  job_salary: "",
  job_tphiring: "",
  job_modality: "",
  job_expiration: moment().format("YYYY/MM/DD"),
  status: "",
  lng: "",
  lat: "",
}

export default JobModel;