import React from "react";
import { Grid } from "@material-ui/core";
import moment from "moment-timezone";
import { CalendarTodayOutlined, FolderOutlined, PinDropSharp } from "@material-ui/icons";

import { Title, Text, Line } from "pages/components/styles";
import Button from "pages/components/Button";

import HIRING_TYPES from "assets/data/hiring-types.json";
import MODALITIES from "assets/data/modalities.json";

function Index(props) {
  const { model, pending, onSubmit, onBack } = props;

  const getTypeHiring = (id) => HIRING_TYPES.find(v => v.id === id)?.label;
  const getModality = (id) => MODALITIES.find(v => v.id === id)?.label;

  return (
    <div>
      <Line>
        <Title big color={"#3FB992"}>
          {model.job_title}
        </Title>
      </Line>

      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Text color={"#15363F"} style={{display: 'flex'}}>
              <FolderOutlined style={{color:'#3FB992'}} /> {model.company}
            </Text>
          </Grid>
          <Grid item xs={12} md={8}>
            <Text medium color={"#15363F"} style={{display: 'flex'}}>
              <PinDropSharp style={{color:'#3FB992'}} /> {`${model.address_street}, ${model.address_number}, ${model.address_neighborhood}, ${model.city_name}-${model.city_uf} - CEP: ${model.address_postal_code} - Complemento: ${model.address_complement}`}
            </Text>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Text style={{ border:'1px solid #3FB992', padding:'5px', borderRadius:'20px', paddingLeft:'20px' ,paddingRight:'20px', marginRight:'10px'}}>
              {getTypeHiring(model.job_tphiring)}
            </Text>
            <Text style={{ border:'1px solid #3FB992', padding:'5px', borderRadius:'20px', paddingLeft:'20px' ,paddingRight:'20px'}}>
              {getModality(model.job_modality)}
            </Text>
          </Grid>
          <Grid item xs={12} md={8}>
            <Text style={{display: 'flex'}}>
              <CalendarTodayOutlined style={{color:'#3FB992'}} />Expira em: {moment(model.job_expiration).format('DD/MM/YYYY')}
            </Text>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Title big>Descrição</Title>
            <Text>{model.job_description}</Text>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Title big>Requerimentos</Title>
            <Text>{model.job_requirements}</Text>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Title big>Salário</Title>
            <Text>{model.job_salary || "A Combinar"}</Text>
          </Grid>
        </Grid>
      </div>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Line>
            <Button disabled={pending === true}
              onClick={onBack}
              width={"150px"}
              bgColor="#909090"
              text={"Voltar"}
            />
            <div style={{ height: "30px", width: "10px" }} />
            <Button disabled={pending === true}
              onClick={onSubmit}
              width={"150px"}
              bgColor="#3FB992"
              text={"Salvar"}
            />
          </Line>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          style={{ textAlign: "right", alignSelf: "center" }}
        >
          <Text>
            Ao publicar esta vaga, seu anuncio já estará diponível para os
            candidatos.
          </Text>
        </Grid>
      </Grid>
    </div>
  );
}

export default Index;
