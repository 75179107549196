import styled from "styled-components";

export const MainContent = styled.div`
  justify-content: center;
  min-height: 200px;
  padding: 50px;
  background: ${props => props.bgColor ? props.bgColor : '#FFFFFF'};
  text-align: center;
  ${props => props.backgroundImage ? {backgroundImage: props.backgroundImage} : {} }
  background-repeat: no-repeat;
  background-position: right;
  background-position-y: 10px;

  @media(max-width: 420px) {
    padding: 10px;
  }
`;

export const HorizontalArea = styled.div`
  padding: 50px;
  background: ${props => props.color ? props.color : '#FFFFFF'};
  ${props => props.center ? {textAlign: 'center'} : {}};
  @media(max-width: 420px) {
    padding: 10px;
  }
`;

export const GrayCenterArea = styled.div`
  padding: 20px;
  background: rgba(4, 4, 4, 0.2);
  border-radius: 6px;
  margin-top: 20px;
`;
