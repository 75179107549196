import React from "react";
import { useSelector } from "react-redux";
import { Grid, makeStyles } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import {
  CustomHeader,
  LogoWhite,
  ProfileIcon,
  CustomLink,
  Text,
  MediaHidden
} from "./styles";

const useStyles = makeStyles((theme) => ({
  customLink: {
    padding:'10px'
  },
  gridItem:{
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center'
  }
}));

const Index = () => {
  const classes = useStyles();
  const { user } = useSelector(state => state.signin);

  return (
    <CustomHeader>
      <Grid container spacing={3}>
        <Grid item xs={4} md={4} className={classes.gridItem}>
          <CustomLink href="/">
            <LogoWhite />
          </CustomLink>
        </Grid>
        <Grid item xs={4} md={3} className={classes.gridItem} style={{justifyContent:'flex-end'}}>
          <CustomLink href="https://conectclinic.com/blog/" target="_blank" className={classes.customLink}>
            <Text bold  color="#FFFFFF">Blog</Text>
          </CustomLink>
        </Grid>
        <Grid item xs={4} md={5} className={classes.gridItem}>
          <CustomLink href={user?'/signout':'/login'} style={{display:'flex'}}>
            {user
              ? <ExitToApp style={{fontSize:'25px', color:'#FFFFFF'}} />
              : <ProfileIcon style={{marginRight:'10px'}} />
            }
            <MediaHidden>
              {user
                ? <Text style={{ color: "#FFFFFF" }}>SAIR</Text>
                : <Text medium style={{ color: "#FFFFFF" }}>
                    Entrar ou <br /> cadastrar
                  </Text>
              }
            </MediaHidden>
          </CustomLink>
        </Grid>
      </Grid>
    </CustomHeader>
  );
};

export default Index;
