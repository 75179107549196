import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Grid, LinearProgress } from "@material-ui/core";
import { ChevronRightSharp, EmailRounded, LinkedIn, PhoneRounded, CakeOutlined } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

import { getUser } from 'services/auth';

import { Title, Text, Line, CustomLink } from "pages/components/styles";
import Header from "pages/components/Header";
import Footer from "pages/components/Footer";
import Button from "pages/components/Button";
import AccountMenu from "../menu";

import { MainContent } from "./styles";

import HIRING_TYPES from "assets/data/hiring-types.json";

import { Creators } from 'store/ducks/curriculums';

export const Index = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pending, data, error } = useSelector(state => state.curriculums);

  const [userLogged, setUserLogged] = useState(null);

  useEffect(() => {
    const _user = getUser();
    setUserLogged(_user);
    if(!_user || !_user.hash) {
      navigate('/login');
    } else {
      dispatch(Creators.fetchCurriculumsByUserId(_user.id));
    }
  },[]);

  const getHiringType = (id) => {
    const type = [...HIRING_TYPES].find(e => e.id == id);
    return type ? String(type.label).split(' ')[0] : "";
  }

  return (
      userLogged && <div>
      <Header />

      <MainContent bgColor={'#FBFBFB'}>
        <Line>
          <Text flex> <CustomLink href="/">Home</CustomLink> <ChevronRightSharp /> <CustomLink href="/conta">Conta</CustomLink> <ChevronRightSharp /> Meu currículo </Text>
          {pending && <LinearProgress />}
        </Line>
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Title big>Meu currículo</Title>
          </Grid>
          <Grid item xs={12} md={4} style={{alignSelf:'center'}}>
            <Button text={(!data || Object.entries(data).length <= 0) ? 'Adicionar Currículo' : 'Alterar dados'} outlined bgColor="#CDCDCD" borderColor="#CDCDCD" onClick={() => navigate('/conta/curriculo/form')} width={'180px'} />
          </Grid>
        </Grid>

        {pending && 
          <>
            <LinearProgress />
            <Title>Buscando dados...</Title>
          </>
        }
        
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <AccountMenu />
            </Grid>
            <Grid item xs={12} md={8}>
              {(!pending && (!data || Object.entries(data).length <= 0)) &&
                <Title>Você não tem curriculo cadastrado!</Title>
              }
              
              {((!pending && data && Object.entries(data).length > 0)) &&
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} style={{display:'grid'}}>
                      <Text bigger bold>{data?.name}</Text>
                      <Text>{data?.profession} - {getHiringType(data?.tphiring)}</Text>
                      <Text flex><CakeOutlined /> {moment(data?.birth).format("DD/MM/YYYY")}</Text>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} style={{display:'grid'}}>
                      <Text bold center>P R O F I L E </Text>
                      <Text>{data?.presentation}</Text>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} style={{display:'grid'}}>
                      <Text bold center>C O N T A T O S</Text>
                      <Text flex><EmailRounded /> {data?.email}</Text>
                      <Text flex><PhoneRounded /> {data?.telephone}</Text>
                      <CustomLink href={data?.linkedin} target="_blank"><Text flex><LinkedIn /> {data?.linkedin}</Text></CustomLink>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} style={{display:'grid'}}>
                      <Text bold center>E N D E R E Ç O</Text>
                      <Text flex>{data?.address_street} {data?.address_number}, {data?.address_complement}, {data?.address_neighborhood} {data?.city?.name}/{data?.city?.uf} - CEP: {data?.address_postal_code} </Text>
                    </Grid>
                  </Grid>
                </>
              }
            </Grid>
          </Grid>

      </MainContent>

      <Footer />
    </div>
  );
}

export default Index
