import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

import Home from 'pages/home';
import Jobs from 'pages/jobs';
import JobsRegistration from 'pages/account/jobs/forms';
import Signup from 'pages/signup';
import SignupSuccess from 'pages/signup/signup-success';
import Account from 'pages/account';
import AccountJobs from 'pages/account/jobs';
import AccountCurriculumForm from 'pages/account/curriculum/forms';
import AccountCurriculumView from 'pages/account/curriculum';
import Login from 'pages/login';
import Signout from 'pages/signout';
import Users from 'pages/users';
import Profile from 'pages/profile';
import Recover from 'pages/recover';
import RecoverConfirm from 'pages/recover/password';
import RecoverSuccess from 'pages/recover/success';

import { getUser } from 'services/auth';
import { Creators } from 'store/ducks/signin';

const Index = (props) => {
  const dispatch = useDispatch();

  useEffect(async () => {
    const userLogged = getUser();
    if(userLogged && userLogged?.hash) {
      dispatch(Creators.setSigninUser(userLogged));
    }
  },[]);

  const NotFound = () => {
    return (<div>Page Not Found</div>);
  };
  
  return (
    <Router history={useNavigate}>
      <Fragment>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signout' element={<Signout />} />
          <Route path='/cadastro' element={<Signup />} />
          <Route path='/cadastro/sucesso' element={<SignupSuccess />} />
          <Route path='/conta' element={<Account />} />
          <Route path='/conta/vagas' element={<AccountJobs />} />
          <Route path='/conta/vaga/nova' element={<JobsRegistration />} />
          <Route path='/conta/curriculo' element={<AccountCurriculumView />} />
          <Route path='/conta/curriculo/form' element={<AccountCurriculumForm />} />
          <Route path='/vagas' element={<Jobs />} />
          <Route path='/vagas/:param1/:param2/:param3' element={<Jobs />} />
          <Route path='/users' element={<Users />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/recover' element={<Recover />} />
          <Route path='/recover/confirm/:code' element={<RecoverConfirm />} />
          <Route path='/recover/success' element={<RecoverSuccess />} />
          <Route path='*' element={<NotFound />}/>
          {/* <Route path='contacts/:id' element={ContactDetail} /> */}
        </Routes>
      </Fragment>
    </Router>
  );
}

export default Index;