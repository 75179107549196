import React from "react";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Text, Line } from "pages/components/styles";
import Button from "pages/components/Button";
import CityAutocomplete from "pages/components/city-autocomplete";

import STATES from "assets/data/br-states.json";

const useStyles = makeStyles((theme) => ({
  textField: {
    borderColor: "#FFFFFF",
    border: "0px",
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    marginRight: "5px",
  },
  selectField:{
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    marginTop: "16px",
    marginBottom: "8px",
  }
}));

function Index(props) {
  const classes = useStyles();
  const { model, setValue, onNext, onBack } = props;

  const onSelecteCity = (_, value) => {
    setValue({
      ...model,
      city_uf: value?.uf,
      city_id: String(value?.id),
      city_name: value?.name,
      city_code: value?.code,
      lng:  value?.lng,
      lat:  value?.lat,
      selectedCity: value
    });
  };

  return (
    <div>
      <Line>
        <Text>Informe o endereço</Text>
      </Line>

      <div>
        <form onSubmit={onNext}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField required variant="outlined" margin="normal" fullWidth
                label="CEP"
                placeholder="01009-001"
                value={model.address_postal_code}
                onChange={(e) =>
                  setValue({
                    ...model,
                    address_postal_code: e.target.value,
                  })
                }
                className={classes.textField}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ textAlign: "right" }}></Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
              <CityAutocomplete
                label="Cidade"
                values={model?.selectedCity}
                onChange={onSelecteCity}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl required fullWidth variant="outlined" className={classes.selectField}>
                <InputLabel id="state-label">Estado</InputLabel>
                <Select labelId="state-label"
                  value={model.city_uf}
                  onChange={(e) =>
                    setValue({ ...model, city_uf: e.target.value })
                  }
                >
                  {[...STATES].map((e, idx) => (
                    <MenuItem key={`state-item-${idx}`} value={e.code}>
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField required variant="outlined" margin="normal" fullWidth
                label="Bairro"
                placeholder="Bairro"
                value={model.address_neighborhood}
                onChange={(e) =>
                  setValue({
                    ...model,
                    address_neighborhood: e.target.value,
                  })
                }
                className={classes.textField}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
              <TextField required variant="outlined" margin="normal" fullWidth
                label="Rua"
                placeholder="Rua"
                value={model.address_street}
                onChange={(e) =>
                  setValue({
                    ...model,
                    address_street: e.target.value,
                  })
                }
                className={classes.textField}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField required variant="outlined" margin="normal" fullWidth
                label="Número"
                placeholder="Número"
                value={model.address_number}
                onChange={(e) =>
                  setValue({
                    ...model,
                    address_number: e.target.value,
                  })
                }
                className={classes.textField}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
              <TextField required variant="outlined" margin="normal" fullWidth
                label="Complemento"
                placeholder="Complemento"
                value={model.address_complement}
                onChange={(e) =>
                  setValue({
                    ...model,
                    address_complement: e.target.value,
                  })
                }
                className={classes.textField}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Line right>
                <Button
                  onClick={onBack}
                  width={"150px"}
                  bgColor="#909090"
                  text={"Voltar"}
                />
                <div style={{ height: "30px", width: "10px" }} />
                <Button
                  type="submit"
                  width={"150px"}
                  bgColor="#3FB992"
                  text={"Continuar"}
                />
              </Line>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default Index;
