import { combineReducers } from "redux";
import city from "./city";
import jobs from "./jobs";
import curriculums from "./curriculums";
import signup from "./signup";
import signin from "./signin";
import upload from "./upload";
import users from "./users";
import validate from "./validate";

const reducers = combineReducers({
  city,
  jobs,
  curriculums,
  signup,
  signin,
  users,
  upload,
  validate
});

export default reducers