import { takeEvery } from 'redux-saga/effects';
import { Types } from 'store/ducks/signin';
import { signin, signout } from './signin';

const signinSagas = [
	takeEvery(Types.FETCH_SIGNIN, signin),
  takeEvery(Types.FETCH_SIGNIN_SUCCESS, () => {
    // Navigation.navigate('Main');
  }),
  takeEvery(Types.FETCH_SIGNIN_FAILURE, () => {
  }),
  takeEvery(Types.SIGNOUT, () => {
    signout();
    // Navigation.navigate('Signin');
  })
]

export default signinSagas;