import { takeEvery } from 'redux-saga/effects';
import { Types } from 'store/ducks/curriculums';
import { postCurriculum, getCurriculums, getCurriculumByUserId } from './curriculums';

const curriculumsSagas = [
	takeEvery(Types.POST_CURRICULUM, postCurriculum),
	takeEvery(Types.FETCH_CURRICULUMS, getCurriculums),
	takeEvery(Types.FETCH_CURRICULUMS_BY_USER_ID, getCurriculumByUserId)
]

export default curriculumsSagas;