import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { TextField, Grid, CircularProgress } from "@material-ui/core";
import { ChevronRightSharp } from "@material-ui/icons";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Alert } from "@material-ui/lab";
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

import moment from "moment-timezone";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Title, Text, Line, CustomLink } from "pages/components/styles";
import Header from "pages/components/Header";
import Footer from "pages/components/Footer";
import Button from "pages/components/Button";
import Card from "pages/components/CardJob";
import CityAutocomplete from "pages/components/city-autocomplete";

import JobView from "./view";
import { MainContent, Content } from "./styles";

import { getUser } from 'services/auth';

import STATES from "assets/data/br-states.json"
import HIRING_TYPES from "assets/data/hiring-types.json"
import CITIES_HIGHLIGHTS from "assets/data/cities-highlights.json"
import COMPANY_BUSINESS from "assets/data/company-business.json"

// Actions
import { Creators } from 'store/ducks/jobs';


const useStyles = makeStyles((theme) => ({
  customButton: {
    backgroundColor: '#1E50A0',
    borderRadius: '8px',
    color: '#FFFFFF',
    fontSize: '0.7rem',
    height: '70%',
    paddingTop: '10px',
    paddingBottom: '10px',
    position: 'relative',
    bottom: '8px',
    alignSelf: 'flex-end'
  },
  textField: {
    borderColor:'#FFFFFF',
    border: '0px',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    marginRight: '5px',
  },
  form: {
    display: 'contents'
  },
  checkedLabel: {
    fontSize:'5px'
  }
}));

function Index(props) {
  const userLogged = getUser();
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pending, data, error, response: insertResponse } = useSelector(state => state.jobs);

  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [dataList, setDataList] = useState([]);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [hiringTypeList, setHiringTypeList] = useState([]);
  const [companyBusinessList, setCompanyBusinessList] = useState([]);
  
  const { param1, param2, param3 } = useParams();
  const [keywords, setKeywords] = useState(String(param1 || '').toLowerCase());

  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedCity, setSelectedCity] = useState([]);

  useEffect(() => {
    if(String(keywords).trim().length >= 4 || String(param3).trim().length > 0){
      dispatch(Creators.fetchJobByKeywordsCityCode(String(keywords).trim(), String(param3).trim()));
    }
  },[param1, param2, param3]);

  useEffect(() => { 
    setDataList(data);
  },[data]);

  useEffect(() => { 
    if(insertResponse){
      setOpenAlert(true);
    }
  },[insertResponse]);

  const handleSubmit = (e) => {
    const {name, uf, code} = selectedCity;
    if(name && uf && code)
      navigate(`/vagas/${keywords}/${name}-${uf}/${code}`);

    navigate(`/vagas/${keywords}/${param2}/${param3}`);
  };

  const onSelecteCity = (_, value) => {
    setSelectedCity(value);
  };

  const handleStateChange = (event) => {
    const { value } = event.target;
    if(stateExists(value)) {
      const newList = stateList.filter(v => v !== value);
      setStateList(newList);
    } else {
      const newList = [...stateList, value];
      setStateList(newList);
    }
  };

  const stateExists = (state) => {
    const _state = stateList.find(v => v === state);
    return !!_state
  }

  const handleCityChange = (event) => {
    const { value } = event.target;
    if(cityExists(value)) {
      const newList = cityList.filter(v => v !== value);
      setCityList(newList);
    } else {
      const newList = [...cityList, value];
      setCityList(newList);
    }
  };

  const cityExists = (city) => {
    const _city = cityList.find(v => v === city);
    return !!_city
  }

  const handleHiringChange = (event) => {
    const { value } = event.target;
    if(hiringExists(value)) {
      const newList = hiringTypeList.filter(v => v !== value);
      setHiringTypeList(newList);
    } else {
      const newList = [...hiringTypeList, value];
      setHiringTypeList(newList);
    }
  };

  const hiringExists = (type) => {
    const _hiring = hiringTypeList.find(v => v === type);
    return !!_hiring
  }

  const handleCompanyBusinessChange = (event) => {
    const { value } = event.target;
    if(companyBusinessExists(value)) {
      const newList = companyBusinessList.filter(v => v !== value);
      setCompanyBusinessList(newList);
    } else {
      const newList = [...companyBusinessList, value];
      setCompanyBusinessList(newList);
    }
  };

  const companyBusinessExists = (id) => {
    const _business = companyBusinessList.find(v => v === id);
    return !!_business
  }

  const handleFilter = () => {
    let _data = data;
    if(hiringTypeList.length > 0){
      _data = [...data].filter(a => hiringTypeList.indexOf(String(a.job_tphiring)) > -1);
    }
    if(stateList.length > 0) {
      _data = [..._data].filter(a => stateList.indexOf(a.city.uf) > -1);
    }
    if(cityList.length > 0) {
      _data = [..._data].filter(a => cityList.indexOf(a.city_code) > -1);
    }
    if(companyBusinessList.length > 0) {
      _data = [..._data].filter(a => companyBusinessList.indexOf(a.company_business) > -1);
    }

    setDataList(_data);
  }

  const handleLoadMore = () => {
  }

  const getHiringType = (id) => {
    const type = [...HIRING_TYPES].find(e => e.id == id);
    return type ? String(type.label).split(' ')[0] : "";
  }

  const handleShowJob = job => {
    setSelectedJob(job);
    setOpenModal(true);
  }

  const handleJobApply = () => {
    setOpenModal(false);
    if(userLogged && userLogged.id){
      const payload = {user_id:userLogged.id, job_id:selectedJob.id, status:1};
      dispatch(Creators.postJoinToJob(payload));
    }
  }

  const handleModalClose = () => setOpenModal(false);


  return <div>
    <Header />

    <MainContent bgColor={'#FBFBFB'}>
      <div style={{position:"fixed", left:'0px', top:'0px', right:'0px', zIndex:'9999'}}>
        <Collapse in={openAlert}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Candidatura realizada com sucesso.
          </Alert>
        </Collapse>
      </div>
      <Line>
        <Text flex> <CustomLink href="/">Home</CustomLink> <ChevronRightSharp /> Vagas</Text>
      </Line>

      <Line>
        <form onSubmit={handleSubmit} className={classes.form}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <TextField disabled={pending}
              id="keywords"
              name="keywords"
              value={keywords}
              onChange={e => setKeywords(e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder="qual a vaga que você está buscando?"
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <CityAutocomplete 
              placeholder={param2}
              values={selectedCity} 
              onChange={onSelecteCity}/>
          </Grid>
          <Grid item xs={12} md={2} style={{alignSelf: 'center'}}>
            <Button disabled={pending}
              type="submit"
              bgColor="#3FB992"
              fullWidth
              text={'Buscar vagas'}
            />
          </Grid>
        </Grid>
        </form>
      </Line>

      <Line top={30} bottom={30}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Title big>Filtros</Title>
            <Line marginBottom={'5px'}>
              <Content>
                <Title color={'#444242'} medium>Área Profissional</Title>
                <Content toFit style={{maxHeight:'300px', overflow:'scroll'}}>
                {
                  [...COMPANY_BUSINESS].map((e, idx) => (
                    <FormControlLabel key={`company-business-${idx}`} size="small" 
                      style={{display:'block'}}
                      control={
                        <Checkbox checked={companyBusinessExists(e.id)} value={e.id}
                          style={{color:'#3FB992'}}
                          onChange={handleCompanyBusinessChange} />
                      }
                      label={<Text medium>{e.label}</Text>}
                    />
                  ))
                }
                </Content>
              </Content>
            </Line>
            <Line>
              <Content>
                <Title color={'#444242'} medium>Tipo de Contrato</Title>
                <Content toFit style={{maxHeight:'300px', overflow:'scroll'}}>
                {
                  [...HIRING_TYPES].map((e, idx) => (
                    <FormControlLabel key={`hiring-type-${idx}`} size="small" 
                      style={{display:'block'}}
                      control={
                        <Checkbox checked={hiringExists(e.id)} value={e.id}
                          style={{color:'#3FB992'}}
                          onChange={handleHiringChange} />
                      }
                      label={<Text medium>{e.label}</Text>}
                    />
                  ))
                }
                </Content>
              </Content>
            </Line>
            <Line>
              <Content>
                <Title color={'#444242'} medium>Estados</Title>
                <Content toFit style={{maxHeight:'300px', overflow:'scroll'}}>
                {
                  [...STATES].map((e, idx) => (
                    <FormControlLabel key={`state-${idx}`} size="small" 
                      style={{display:'block'}}
                      control={
                        <Checkbox checked={stateExists(e.code)} value={e.code}
                          style={{color:'#3FB992'}}
                          onChange={handleStateChange} />
                      }
                      label={<Text medium>{e.name}</Text>}
                    />
                  ))
                }
                </Content>
              </Content>
            </Line>
            <Line>
              <Content>
                <Title color={'#444242'} medium>Cidades</Title>
                <Content toFit style={{maxHeight:'300px', overflow:'scroll'}}>
                {
                  [...CITIES_HIGHLIGHTS].map((e, idx) => (
                    <FormControlLabel key={`city-highlight-${idx}`} size="small" 
                      style={{display:'block'}}
                      control={
                        <Checkbox checked={cityExists(e.code)} value={e.code}
                          style={{color:'#3FB992'}}
                          onChange={handleCityChange} />
                      }
                      label={<Text medium>{`${e.name} - ${e.uf}`}</Text>}
                    />
                  ))
                }
                </Content>
              </Content>
            </Line>

            <Line top={30} bottom={30}>
              <Button text={'FILTRAR'} onClick={handleFilter}
                fullWidth outlined borderColor={'#C4C4C4'} />
            </Line>
          </Grid>

          <Grid item xs={12} md={8}>
            <Title big>Todas as vagas</Title>
            <Text medium>Mostrando {[...dataList].length} vagas de trabalho</Text>

            {pending && 
              <div style={{paddingTop:'30px', paddingBottom:'30px', textAlign:'center'}}>
                <CircularProgress style={{color:'#3FB992'}} />
              </div>
            }

            <div style={{paddingTop:'30px', paddingBottom:'30px'}}>
            {
              [...dataList].map((e, idx) => (
                <Card key={`job-${idx}`}
                  title={e.job_title}
                  company={e.company}
                  local={`${e.city?.name}-${e.city?.uf}`}
                  description={e.job_description}
                  date={moment(e.created_at).format("DD/MM/YYYY")}
                  hiringType={getHiringType(e.job_tphiring)}
                  wage={`Salário - ${e.job_salary}`}
                  onClick={() => handleShowJob(e)}
                />
              ))
            }
            </div>

            {/* <Line top={30} bottom={30} centerContent>
              <Button width={'auto'} text={'Carregar mais'} onClick={handleLoadMore}
                outlined borderColor={'#C4C4C4'} />
            </Line> */}
          </Grid>
        </Grid>
      </Line>

    </MainContent>
    <div>
      <Dialog fullScreen={fullScreen}
          open={openModal}
          onClose={handleModalClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <Button outlined width={'100px'} onClick={handleModalClose} text="Voltar" />
          </DialogTitle>
          <DialogContent dividers={true}>
            <JobView job={selectedJob} />
          </DialogContent>
          <DialogActions>
            <Button bgColor={'#909090'} onClick={handleModalClose} text="Voltar" />
            <Button onClick={handleJobApply} text="Candidatar-se" />
          </DialogActions>
        </Dialog>
    </div>
    <Footer />
  </div>;
}

export default Index;
