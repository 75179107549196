import moment from "moment-timezone";
const CurriculumModel = {
  id: "",
  user_id: "",
  name: "",
  email: "",
  telephone: "",
  linkedin: "",
  birth: "",
  tphiring: "",
  profession: "",
  presentation: "",
  city_id: "",
  city_code: "",
  city_name: "",
  city_uf: "",
  address_neighborhood: "",
  address_street: "",
  address_postal_code: "",
  address_number: "",
  address_complement: "",
  lng: "",
  lat: ""
}

export default CurriculumModel;