import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Grid, CircularProgress } from "@material-ui/core";
import { SearchRounded } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

import Header from "pages/components/Header";
import Footer from "pages/components/Footer";
import ButtonFit from "pages/components/ButtonFit";
import Button from "pages/components/Button";
import Card from "pages/components/CardJob";
import CityAutocomplete from "pages/components/city-autocomplete";

import { MainContent, HorizontalArea, GrayCenterArea } from "./styles";
import { Title, Text, Line } from "pages/components/styles";

import { IconCompanies, IconCurrency, IconJobs, IconSignupFree} from "pages/components/custom-icons";
import ImageArea1 from "assets/para-candidatos.png";
import ImageArea2 from "assets/para-recrutadores.png";
import PeopleDoctors from "assets/people-doctors-transparent.png";

import HIRING_TYPES from "assets/data/hiring-types.json";

// Actions
import { Creators } from 'store/ducks/jobs';

const useStyles = makeStyles((theme) => ({
  customButton: {
    backgroundColor: '#1E50A0',
    borderRadius: '8px',
    color: '#FFFFFF',
    fontSize: '0.7rem',
    height: '70%',
    paddingTop: '10px',
    paddingBottom: '10px',
    position: 'relative',
    bottom: '8px'
  },
  textField: {
    borderColor:'#FFFFFF',
    border: '0px',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
  },
  form: {
    display: 'contents'
  }
}));

function Index() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pending, data, error } = useSelector(state => state.jobs);
  const [keywords, setKeywords] = useState('');
  const [selectedCity, setSelectedCity] = useState([]);
  const [alertEmail, setAlertEmail] = useState('');
  const [alertPeriod, setAlertPeriod] = useState('');

  useEffect(() => {
    dispatch(Creators.fetchJobByLast(3)); 
  },[]);

  const handleSubmit = (e) => {
    const {name, uf, code} = selectedCity;
    navigate(`vagas/${keywords}/${name}-${uf}/${code}`);
  };

  const onSelecteCity = (_, value) => {
    setSelectedCity(value);
  };

  const getHiringType = (id) => {
    const type = [...HIRING_TYPES].find(e => e.id == id);
    return type ? String(type.label).split(' ')[0] : "";
  }

  return <div>
    <Header />

    <MainContent bgColor={'rgba(63, 185, 146, 0.85)'} backgroundImage={PeopleDoctors}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={1} />
        <Grid item xs={12} md={10}>
          <Title big color={'#FFFFFF'}>Encontre a vaga ideal para o seu objetivo</Title>
          <Title medium color={'#FFFFFF'}>Principais áreas: beleza, saúde, bem-estar e muito mais.</Title>
        </Grid>
        <Grid item xs={12} md={1} />
      </Grid>
      <Line>
        <Grid container spacing={3}>
          <Grid item xs={12} md={1} />
          <Grid item xs={12} md={10}>
            <GrayCenterArea>
              <Line>
                <SearchRounded style={{ marginRight:'10px', color:'#FFFFFF' }} />
                <Text color={'#FFFFFF'}>Encontre a sua vaga de trabalho </Text>
              </Line>

              <Line>
                <form onSubmit={handleSubmit} className={classes.form}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <TextField disabled={pending}
                        id="keywords"
                        name="keywords"
                        value={keywords}
                        onChange={e => setKeywords(e.target.value)}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        placeholder="Palavra chave"
                        autoFocus
                        className={classes.textField}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CityAutocomplete showState values={selectedCity} onChange={onSelecteCity}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Button disabled={pending} style={{marginTop:'16px', marginBottom:'8px'}}
                        fullWidth
                        type="submit"
                        bgColor="#1E50A0"
                        text={'Buscar vagas'}
                      />
                    </Grid>
                  </Grid>
                </form>
                
              </Line>
            </GrayCenterArea>
          </Grid>
          <Grid item xs={12} md={1} />
        </Grid>
      </Line>

      <Line style={{paddingTop:30,paddingBottom:10}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={1} />
          <Grid item xs={12} md={5}>
            <ButtonFit onClick={() => navigate('/conta/curriculo/form')}
              iconName="home" text="Cadastrar meu currículo"/>
          </Grid>
          <Grid item xs={12} md={5}>
            <ButtonFit onClick={() => navigate('/conta/vaga/nova')}
              iconName="folder" text="Publicar vagas de trabalho" />
          </Grid>
          <Grid item xs={12} md={1} />
        </Grid>
      </Line>

    </MainContent>
    
    <HorizontalArea color={'#FBFBFB'}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={2}>
            <IconJobs />
            <Text color={'#383838'} bold>As melhores vagas</Text>
            <Text color={'#383838'} size={'10px'} block>Encontre as melhores vagas na área tão desejada.</Text>
        </Grid>
        <Grid item xs={12} md={2}>
            <IconCompanies />
            <Text color={'#383838'} bold>Diversas empresas</Text>
            <Text color={'#383838'} size={'10px'} block>Candidate-se às melhores clínicas ou consultórios.</Text>
        </Grid>
        <Grid item xs={12} md={2}>
            <IconSignupFree />
            <Text color={'#383838'} bold>Cadastro gratuito</Text>
            <Text color={'#383838'} size={'10px'} block>Cadastre seu currículo e aumente suas chances.</Text>
        </Grid>
        <Grid item xs={12} md={2}>
            <IconCurrency />
            <Text color={'#383838'} bold>Mais oportunidades</Text>
            <Text color={'#383838'} size={'10px'} block>Conquiste a tão sonhada vaga que vem buscando.</Text>
        </Grid>
        <Grid item xs={12} md={2} />
      </Grid>
    </HorizontalArea>

    <HorizontalArea>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
            <Text color={'#3FB992'} bold>PARA CANDIDATOS</Text>
            <br /><br />
            <Title variant="h5" >Cadastre grátis  seu cv e aumente suas chances na plataforma</Title>
            <br /><br />
            <Text color={'gray'} size={'12px'}>Mostre mais do que o óbvio. Cadastre seu cv e impulsione o seu perfil para os recrutadores.</Text>
            <br /><br />
            <Button text={'Cadastrar meu cv'} onClick={() => navigate('/conta/curriculo/form')} width={'180px'} />
        </Grid>
        <Grid item xs={12} md={6} style={{textAlign:'right'}}>
          <img src={ImageArea1} style={{width:'80%'}} />
        </Grid>
      </Grid>
    </HorizontalArea>

    <HorizontalArea color={'#FBFBFB'}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <img src={ImageArea2} style={{width:'80%'}} />
        </Grid>
        <Grid item xs={12} md={6} style={{textAlign:'right'}}>
          <Text color={'#3FB992'} bold>PARA RECRUTADORES</Text>
          <br /><br />
          <Title variant="h5"  style={{textAlign:'right'}}>Publique as suas vagas de forma simples e rápida</Title>
          <br /><br />
          <Text color={'gray'} size={'12px'}>Os profissioanais poderão se candidadtar às suas vagas imediatamente. Está sperando o que para publicar?</Text>
          <br /><br />
          <Button text={'Publicar vagas'} onClick={() => navigate('/conta/vaga/nova')} width={'180px'} />
        </Grid>
      </Grid>
    </HorizontalArea>

    {/* <HorizontalArea>
      <Title medium >Vagas por categoria</Title>
      <br /><br />
    </HorizontalArea>

    <HorizontalArea>
      <Title medium >Vagas por cidade</Title>
      <br /><br />
    </HorizontalArea> */}
    
    <div style={{padding:'20px', paddingBottom:'50px'}}>
      <Grid container spacing={3}>
        <Grid item md={3} sx={{display: { xs: "none"}}}></Grid>
        <Grid item md={6}>
            <div style={{textAlign:'center', marginBottom:'15px'}}>
            <Text color={'#3FB992'} size={'10px'} bold style={{textAlign:'center'}}>DESCUBRA</Text>
          </div>
          <Title variant="h5"  style={{textAlign:'center'}}>Últimas vagas publicadas</Title>
          <br /><br />
          {pending && 
            <div style={{paddingTop:'30px', paddingBottom:'30px', textAlign:'center'}}>
              <CircularProgress style={{color:'#3FB992'}} />
            </div>
          }

          <div style={{paddingTop:'30px', paddingBottom:'30px'}}>
            {
              [...data].map((e, idx) => (
                <Card key={`job-${idx}`}
                  title={e.job_title}
                  company={e.company}
                  local={`${e.city?.name}-${e.city?.uf}`}
                  description={e.job_description}
                  date={moment(e.created_at).format("DD/MM/YYYY")}
                  hiringType={getHiringType(e.job_tphiring)}
                  wage={`Salário - ${e.job_salary}`}
                />
              ))
            }
          </div>
        </Grid>
        <Grid item md={3} sx={{display: { xs: "none"}}}></Grid>
      </Grid>
    </div>
    
    {/* <div style={{padding:'10px', paddingBottom:'50px', textAlign:'center'}}>
      <Button text={'Ver todas'} width={'180px'} outlined borderColor={'#C4C4C4'} />
    </div> */}

    {/* <HorizontalArea color={'#FBFBFB'}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
            <Text color={'#3FB992'} bold>SEJA O PRIMEIRO</Text>
            <br /><br />
            <Title variant="h5" >Receba alerta de vagas</Title>
            <br /><br />
            <Text color={'gray'} size={'12px'}>Receba diariamente informações e vagas para se cadastrar antes de todos e garantir mais chances de conquistar a tão sonhada vaga.</Text>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4} style={{textAlign:'right'}}>
          <HorizontalArea>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField disabled={pending}
                    id="alertEmail"
                    name="alertEmail"
                    value={alertEmail}
                    onChange={e => setAlertEmail(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    placeholder="Email"
                    type="Email"
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs={12}>

                  <TextField disabled={pending}
                    name="alertPeriod"
                    id="alertPeriod"
                    value={alertPeriod}
                    onChange={e => setAlertPeriod(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    placeholder="Diário"
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button disabled={pending}
                    fullWidth
                    type="submit"
                    onClick={handleSubmit}
                    text={'Criar alerta'}
                  />
                  <Text color={'gray'} size={'12px'}>Você poderá descadastrar-se quando quiser</Text>
                </Grid>
              </Grid>
            </form>
          </HorizontalArea>
        </Grid>
      </Grid>
    </HorizontalArea> */}

    <Footer />
  </div>;
}

export default Index;
