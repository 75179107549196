import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  postJob: ['data'],
  postJoinToJob: ['data'],
  postJoinToJobSuccess: ['data'],
  fetchJobById: ['id'],
  fetchJobByKeywordsCityCode: ['keywords', 'code'],
  fetchJobByLast: ['count'],
  fetchJobByUserId: ['userId'],
  setJobSuccess: ['data'],
  setJobFailure: ['error']
});

/**
 * Handlers
 */
const STATE = {
  pending: false,
  response: null,
  data: [],
  error: null
}
const INITIAL_STATE = STATE;

// Job
const insertJob = (state, { data }) => {
  return {...state, pending:true, error:null};
};

const insertJoinToJob = (state, { data }) => {
  return {...state, response: null, pending:true, error:null};
};

const insertJoinToJobSuccess = (state, { data }) => {
  return {...state, response: data, pending:false, error:null};
};

const getJobById = state => {
  return {...state, pending:true, error:null};
};

const getJobByKeywordsCityCode = state => {
  return {...state, pending:true, error:null};
};

const getJobByLast = state => {
  return {...state, pending:true, error:null};
};

const getJobByUserId = state => {
  return {...state, pending:true, error:null};
};

const setJobSuccess = (state, { data }) => {
  return {...state, data, pending:false, error:false};
};

const setJobFailure = (state, { error }) => {
  return {...state, pending: false, error };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.POST_JOB]: insertJob,
  [Types.POST_JOIN_TO_JOB]: insertJoinToJob,
  [Types.POST_JOIN_TO_JOB_SUCCESS]: insertJoinToJobSuccess,
  [Types.FETCH_JOB_BY_ID]: getJobById,
  [Types.FETCH_JOB_BY_KEYWORDS_CITY_CODE]: getJobByKeywordsCityCode,
  [Types.FETCH_JOB_BY_LAST]: getJobByLast,
  [Types.FETCH_JOB_BY_USER_ID]: getJobByUserId,
  [Types.SET_JOB_SUCCESS]: setJobSuccess,
  [Types.SET_JOB_FAILURE]: setJobFailure
});