import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from "@material-ui/core";
import { ChevronRightSharp, EmailRounded } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import Header from "pages/components/Header";
import Footer from "pages/components/Footer";
import { Title, Text, Line, CustomLink } from "pages/components/styles";

import { MainContent, GrayCenterArea} from "../styles";
import CurriculumModel from "models/curriculum";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";

import { getUser } from 'services/auth';

// Actions
import { Creators } from 'store/ducks/curriculums';
import { Creators as CreatorsUser } from 'store/ducks/users';
import { GRID_DEFAULT_LOCALE_TEXT } from "@material-ui/data-grid";

function Index(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pending: userPending, data: userData } = useSelector(state => state.users);
  const { data, pending, updated, error } = useSelector(state => state.curriculums);
  
  const [userLogged, setUserLogged] = useState(null);

  const [model, setModel] = useState(CurriculumModel);
  const [step, setStep] = useState(1);

  const backStep = () => setStep(step > 1 ? step-1 : 1);
  const nextStep = () => setStep(step < 3 ? step+1 : 3);
  const setValue = value => setModel(value);

  useEffect(() => {
    if(data && data.id) {
      setModel({...model, ...data, city_name:data?.city.name, city_uf:data?.city.uf, selectedCity:data?.city })
    }
  },[data]);

  useEffect(() => {
    if(updated === true) {
      navigate('/conta/curriculo');
    }
  },[updated]);

  useEffect(() => {
    const _user = getUser();
    setUserLogged(_user);
    if(!_user || !_user.hash) {
      navigate('/login');
    } else {
      dispatch(CreatorsUser.fetchUser(_user?.id));
      dispatch(Creators.fetchCurriculumsByUserId(_user.id));
    }
    setValue({...model, user_id:String(_user?.id), status:"1"});
  },[]);

  const onSubmit = () => {
    const _user = getUser();
    const data = {...model, user_id:String(_user?.id), status:"1",}
    dispatch(Creators.postCurriculum(data));
  }

  return <div>
    <Header />

      <MainContent bgColor={'#FFFFFF'}>
        <Line>
          <Text flex> <CustomLink href="/">Home</CustomLink> <ChevronRightSharp /> <CustomLink href="/conta">Conta</CustomLink> <ChevronRightSharp /> <CustomLink href="/conta/curriculo">Meu currículo</CustomLink> <ChevronRightSharp /> Editar </Text>
        </Line>

        <div style={{textAlign:'center'}}>
          <Title bigger center color={'#3FB992'}>Meu Currículo</Title>
          <Text medium>Adicione as informações básicas do currículo.</Text>
        </div>

        { (pending || userPending ) && <LinearProgress /> }

        { (!pending && !userPending ) &&
          <div>
            <GrayCenterArea light>
              {step === 1 && <Step1 model={model} setValue={setValue} onNext={nextStep} onBack={backStep} />}
              {step === 2 && <Step2 model={model} setValue={setValue} onNext={nextStep} onBack={backStep} />}
              {step === 3 && 
                <>
                  {pending && <LinearProgress />}
                  <Step3 model={model} pending={pending} setValue={setValue} onSubmit={onSubmit} onBack={backStep} />
                  {pending && <LinearProgress />}
                </>
              }
            </GrayCenterArea>
          </div>
        }
      </MainContent>

    <Footer />
  </div>
}

export default Index;
