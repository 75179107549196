import { call, put } from 'redux-saga/effects';
import api from 'services/api';
import { Creators } from 'store/ducks/users';

export function* getUser({id}) {
  try {
    const res = yield call(api.get, `/v1/user/user?id=${id}`);
    const { status, statusText, data } = res;
    
    if((status == 200 || status==201)){
      const user = data.body;
      yield put(Creators.fetchUserSuccess(user));
    } else {
      yield put(
        Creators.fetchUserFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.fetchUserFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* getUsers() {
  try {
    const res = yield call(api.get, `/v1/user/user`);
    const { status, statusText, data } = res;
    
    if((status == 200 || status==201)){
      const users = data.body;
      yield put(Creators.fetchUsersSuccess(users));
    } else {
      yield put(
        Creators.fetchUsersFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.fetchUsersFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* insertUser({ user }) {
  try {
    const res = yield call(api.post, `/v1/user/user`, user);
    const { status, statusText, data } = res;
    
    if((status == 200 || status==201)){
      const _data = data.body;
      yield put(Creators.postUserSuccess(_data));
    } else {
      yield put(
        Creators.postUserFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.postUserFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* updateUser({ user }) {
  try {
    const res = yield call(api.put, `/v1/user/user`, user);
    const { status, statusText, data } = res;
    
    if((status == 200 || status==201)){
      const _data = data.body;
      yield put(Creators.putUserSuccess(_data));
    } else {
      yield put(
        Creators.putUserFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.putUserFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* recoverPassword({ user }) {
  console.log('recoverPassword::user', user);
  try {
    const res = yield call(api.put, `/v1/public/recoverpassword?recoverPassword=true`, user);
    console.log('res', res);
    const { status, statusText, data } = res;
    
    if((status == 200 || status==201)){
      const _data = data.body;
      yield put(Creators.putUserSuccess(_data));
    } else {
      yield put(
        Creators.putUserFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.putUserFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

export function* removeUser({ id }) {
  try {
    const res = yield call(api.delete, `/v1/user/user`, {data: {id}});
    const { status, statusText, data } = res;
    if((status == 200 || status==201)){
      const _data = data.body;
      yield put(Creators.deleteUserSuccess(_data));
    } else {
      yield put(
        Creators.deleteUserFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.deleteUserFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}

// usuários do app

export function* getUsersApp() {
  try {
    const res = yield call(api.get, `/v1/user/user`);
    const { status, statusText, data } = res;
    
    if((status == 200 || status==201)){
      const users = data.body;
      yield put(Creators.fetchUsersAppSuccess(users));
    } else {
      yield put(
        Creators.fetchUsersAppFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.fetchUsersAppFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}


export function* removeUserApp({ id }) {
  try {
    const res = yield call(api.delete, `/v1/user/user`, {data: {id}});
    const { status, statusText, data } = res;
    if((status == 200 || status==201)){
      const _data = data.body;
      yield put(Creators.deleteUserAppSuccess(_data));
    } else {
      yield put(
        Creators.deleteUserAppFailure({
          status: status==='NOT_AUTHORIZED'?401:status,
          message: status==='NOT_AUTHORIZED'?status:statusText,
        })
      );
    }
  } catch (e) {
    const { response, message } = e;
    yield put(
      Creators.deleteUserAppFailure({
        status: response ? response.data.statusCode || response.status : null,
        message,
      }),
    );
  }
}
