import { all } from 'redux-saga/effects';
import citiesSagas from './city';
import jobsSagas from './jobs';
import curriculumsSagas from './curriculums';
import signinSagas from './signin';
import signupSagas from './signup';
import usersSagas from './users';
import validateSagas from './validate';

export default function* rootSaga() {
	yield all([
		...citiesSagas,
		...jobsSagas,
		...curriculumsSagas,
		...signinSagas,
		...signupSagas,
		...usersSagas,
		...validateSagas
	]);
}
