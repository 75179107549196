import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Grid, LinearProgress } from "@material-ui/core";
import { ChevronRightSharp } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

import { getUser } from 'services/auth';

import { Title, Text, Line, CustomLink } from "pages/components/styles";
import Header from "pages/components/Header";
import Footer from "pages/components/Footer";
import Card from "pages/components/CardJob";
import AccountMenu from "../menu";
import { MainContent } from "./styles";

import { Creators } from 'store/ducks/jobs';

import HIRING_TYPES from "assets/data/hiring-types.json";

export const Index = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pending, data, error } = useSelector(state => state.jobs);

  const [userLogged, setUserLogged] = useState(null);

  useEffect(() => {
    const _user = getUser();
    setUserLogged(_user);
    if(!_user || !_user.hash) {
      navigate('/login');
    } else {
      dispatch(Creators.fetchJobByUserId(_user.id));
    }
  },[]);

  const getHiringType = (id) => {
    const type = [...HIRING_TYPES].find(e => e.id == id);
    return type ? String(type.label).split(' ')[0] : "";
  }

  return (
      userLogged && <div>
      <Header />

      <MainContent bgColor={'#FBFBFB'}>
        <Line>
          <Text flex> <CustomLink href="/">Home</CustomLink> <ChevronRightSharp /> <CustomLink href="/conta">Conta</CustomLink> <ChevronRightSharp /> Vagas </Text>
        </Line>
        {pending && 
          <>
            <LinearProgress />
            <Title>Buscando dados...</Title>
          </>
        }
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <AccountMenu />
          </Grid>
          <Grid item xs={12} md={8}>
            {(!pending && (!data || [...data].length <= 0)) &&
              <Title>Você não tem vagas cadastradas!</Title>
            }
            
            {(!pending && (data && [...data].length > 0)) &&
              <div style={{paddingTop:'30px', paddingBottom:'30px'}}>
                {
                  [...data].map((e, idx) => (
                    <Card key={`job-${idx}`}
                      title={e.job_title}
                      company={e.company}
                      local={`${e.city?.name}-${e.city?.uf}`}
                      description={e.job_description}
                      date={moment(e.created_at).format("DD/MM/YYYY")}
                      hiringType={getHiringType(e.job_tphiring)}
                      wage={`Salário - ${e.job_salary}`}
                    />
                  ))
                }
              </div>
            }
          </Grid>
        </Grid>

      </MainContent>

      <Footer />
    </div>
  );
}

export default Index
